import classNames from "classnames";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import BackToOverview from "../../components/BackToOverview";
import RewardCard from "../../components/cards/RewardCard";
import DownloadTeaser from "../../components/DownloadTeaserBig";
import { useGlobalState } from "../../state/globalState";
import {
  IFilterItem,
  IMergedReward,
  IPartner,
  IReward,
} from "../../types/gatsby";

export interface PartnersBonusesProps {
  data: {
    bonusesData: {
      edges: {
        node: IReward;
      }[];
    };
    partnerData: {
      edges: {
        node: IPartner;
      }[];
    };
    filterData: {
      edges: {
        node: IFilterItem;
      }[];
    };
  };
}

const PartnersBonuses: React.FC<PartnersBonusesProps> = ({
  data: { bonusesData, partnerData, filterData },
}) => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const { t } = useTranslation(["partners"]);

  const [bonusesCategoryFilter, setPartnerCategoryFilter] =
    React.useState<string>("all");

  const mergedBonusesData = React.useMemo(
    () =>
      bonusesData.edges.reduce<IMergedReward[]>((acc, { node: bonus }) => {
        const partner = partnerData.edges.find(
          ({ node: p }) => p.alternative_id === bonus.partnerId
        );
        if (partner) {
          acc.push({
            ...bonus,
            partner: {
              coreIndustry: partner.node.coreIndustry,
              logo: partner.node.logo_localImage,
              name: partner.node.name,
            },
          });
        }
        return acc;
      }, []),
    [partnerData, bonusesData]
  );

  const filteredBonusesData = React.useMemo(() => {
    const selectedFilter = filterData.edges.find(
      ({ node: filter }) => filter.id === bonusesCategoryFilter
    );
    const itemIds =
      selectedFilter !== undefined
        ? selectedFilter.node.itemIds
        : filterData.edges.flatMap(({ node: filter }) => filter.itemIds);
    return mergedBonusesData.filter((b) => itemIds.includes(b.rewardId));
  }, [bonusesCategoryFilter]);

  const handleCategoryFilterClick = React.useCallback((id: string) => {
    setPartnerCategoryFilter(id);
  }, []);

  return (
    <div>
      <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
        <div className="z-10">
          <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
            <Trans i18nKey="partners:title_sub_bonuses">
              <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                Kauf ein{" "}
              </span>
              <span className="font-black">mit deinen</span>
              <span className="inline-block max-w-full font-black break-words text-amber-500 with-hyphens">
                PLAN3T Coins.
              </span>
            </Trans>
          </h1>
        </div>
      </section>

      <div className="px-4 pb-16 container-4xl">
        <div className="prose-lg text-gray-500">
          <div>
            <p>{t("partners:description_sub_bonuses")}</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="pt-12 container-7xl">
          <BackToOverview
            to="/our-partners"
            cta={t("partners:cta_show_general_overview")}
          />
        </div>
        <div className="flex pt-16 overflow-x-scroll sm:justify-center sm:flex-wrap sm:overflow-x-auto container-7xl">
          <button
            className={classNames([
              "mb-4 mr-4",
              bonusesCategoryFilter === "all"
                ? "button-blue-filled"
                : "button-blue-outline",
            ])}
            onClick={() => {
              handleCategoryFilterClick("all");
            }}
          >
            {t("partners:filter.all")}
          </button>
          {filterData.edges.map(({ node: filter }) => (
            <button
              key={filter.id}
              className={classNames([
                "mb-4 mr-4",
                bonusesCategoryFilter === filter.id
                  ? "button-blue-filled"
                  : "button-blue-outline",
              ])}
              onClick={() => {
                handleCategoryFilterClick(filter.id);
              }}
            >
              {filter.title}
            </button>
          ))}
        </div>
        <div className="px-4 py-16 overflow-visible container-7xl">
          <div className="grid grid-cols-6 gap-6">
            {filteredBonusesData.map((reward) => (
              <RewardCard
                reward={reward}
                partner={reward.partner}
                key={reward.id}
              />
            ))}
          </div>
        </div>
      </div>
      <DownloadTeaser />
    </div>
  );
};

export const query = graphql`
  query BonusesQuery($language: String!, $dateNow: Date!) {
    bonusesData: allPlan3TReward(
      filter: {
        id: { ne: "dummy" }
        validUntil: { gt: $dateNow }
        fields: { rewardType: { eq: "bonus" } }
      }
      sort: { fields: fields___weight }
    ) {
      edges {
        node {
          id
          rewardId
          partnerId
          title
          description
          price {
            amount
          }
          localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 250
                quality: 80
                placeholder: BLURRED
                blurredOptions: { width: 72 }
              )
            }
          }
          fields {
            categories {
              id
              title
            }
          }
        }
      }
    }
    partnerData: allPlan3TPartner(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          id
          alternative_id
          name
          tagLine
          logo_localImage {
            id
            childImageSharp {
              gatsbyImageData(width: 72, quality: 90, placeholder: BLURRED)
            }
          }
        }
      }
    }
    filterData: allPlan3TRewardBonusCategory(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          id
          title
          itemIds
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PartnersBonuses;
